// Mood Board Color
export const BG_PRIMARY = '#F7F4F4';
export const BG_SECONDARY = '#786551';
export const BG_ALTERNATIVE = '#FFFFFF';
export const BG_OPACITY = '#323030';

// Text Color
export const TEXT_PRIMARY = '#786551';
export const TEXT_SECONDARY = '#786551';
export const TEXT_ALTERNATIVE = '#FEFEFE';

// Music & Navigation Color
export const NAVIGATION_COLOR = '#3f3730'; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya
